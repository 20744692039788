export const LOGIN = 'auth/login';
export const TOTAL_USERS = 'users/totalUsers';
export const ADD_USER = 'users/addUser';
export const GET_CATEGORIES = 'categories/list';
export const ADD_ITEM = 'items/add';
export const GET_ITEMS = 'items/list';
export const GET_ITEM = 'items/getItem';
export const ADD_SUB_ITEM = 'items/addSubItem';
export const GET_SUB_ITEMS = 'items/getSubItems';
export const GET_SUB_ITEM = 'items/getSubItem';
export const GET_USERS = 'users/getUsers';
export const ADD_ORDER = 'orders/addOrder';
export const ADD_USER_ORDER = 'orders/addUserOrder';
export const GET_ORDERS = 'orders/getOrders';
export const GET_ORDER = 'orders/getOrder';
export const UPDATE_ORDER = 'orders/updateOrderStatus';
export const ADD_SUBSCRIPTION = 'subscriptions/addSubscription';
export const GET_SUBSCRIPTIONS = 'subscriptions/getSubscriptions';
export const GET_MY_SUBSCRIPTIONS = 'subscriptions/getMySubscriptions';
export const UPDATE_MY_SUBSCRIPTION = 'orders/updateMySubscription';
export const DELETE_MY_SUBSCRIPTION = 'orders/deleteMySubscription';
export const ADD_ZONE = 'zones/addZone';
export const GET_ZONES = 'zones/getZones';
export const GET_ITEM_MAPPINGS = 'items/getItemMappings';
export const ADD_ITEM_MAPPING = 'items/addItemMapping';
export const ADD_USER_ADDRESS = 'users/addUserAddress';
export const GET_USER_ADDRESSES = 'users/getUserAddresses';
export const GET_USER_ADDRESS = 'users/getUserAddress';
export const GET_DELIVERY_SLOTS = 'zones/getDeliverySlots';
export const GET_DELIVERY_ORDER_DATES = 'orders/getOrderDates';
export const UPDATE_USER_IMAGE = 'users/updateUserImage';
export const GET_USER = 'users/getUser';
export const GET_PAYMENTS = 'payments/getPayments';
export const GET_REFUNDS = 'payments/getRefunds';
export const UPDATE_USER_DETAILS = 'users/updateUser';
